import React, { Component } from 'react'
import styles from './producthead.module.scss'
import Image from "../../images/homepage-image.png"
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade';

class ProductHead extends Component {
    render() {
      return (
        <div className={styles.productHead}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.text}>
              <Fade>
  
                <div className={styles.code}>
                  {`<we> </innovate> `}
                </div>
              </Fade>
              <Fade left>
  
                <h1>
                  Explore Our Products
                </h1>
              </Fade>
              </div>
              <Zoom>
                <div className={styles.image}>
                  <img src={Image} alt="brain" />
                </div>
              </Zoom>
            </div>
            <Fade bottom>
            {/* <div className={styles.block}>
              <h2>
                We build top quality mobile and web based solutions that drive business success for our clients 
              </h2>
            </div> */}
            </Fade>
          </div>
        </div>
      )
    }
  }
  
  export default ProductHead