import React, { Component } from 'react'
import styles from "./productitem.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';


class ProductItem extends Component {

  state = {
    readMoreIndex: ""
  }

  renderMore = (i) => {
   this.setState({
     readMoreIndex: i,
   })
  }
  
  showLess = () => {
    this.setState({
      readMoreIndex: "",
    })
  }
  
  render() {
    const { data } = this.props
    return (
      <div className={styles.products}>
          <div className={styles.container}>
          <Fade bottom>
            <div className={styles.head}>
              <h3>Our Products</h3>
              <p>Research & Development is at the core of what we do. Our product portfolio is growing with products that were developed to innovatively solve relevant human problems.</p>
            </div>
          </Fade>
            <div className={styles.productList}>
             {data.allContentfulOurProducts.edges.map((edge, i) => (
              <div className={styles.product} key={edge.node.title}>
              <Zoom>
                <div className={styles.image}>
                  <img src={edge.node.heroImage.file.url} alt={edge.node.title} />
                  <a className={styles.link} href={edge.node.link} target="_blank" rel="noopener noreferrer">Visit Site <FontAwesomeIcon icon={faAngleRight}/> </a>
                </div>
              </Zoom>
                <div className={styles.content}>
                  <div className={styles.tag}>
                    {edge.node.tags.map((tag, i) => (
                      <span key={i}>{tag}</span>
                    ))}
                  </div>
                  <Slide top>
                    <div>
                      <h4>{edge.node.title}</h4>
                      <p>{edge.node.description}</p>
                    </div>
                  
                    <div>
                        <h4>The Product</h4>
                        <div dangerouslySetInnerHTML={{__html: edge.node.about.childMarkdownRemark.html}}></div>
                    </div> 
                    </Slide>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
    )
  }
}

export default ProductItem