import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProductItem from "../ProductItem/ProductItem";

const ProductList = () => {
    const data = useStaticQuery(graphql`
        query{
            allContentfulOurProducts(
                sort:{
                fields:createdAt,
                order:DESC
              }){
            edges{
                node{
                title
                description
                link
                about{
                    childMarkdownRemark{
                    html
                    }
                }
                tags
                heroImage{
                    file{
                    url
                    }
                }
                
                }
            }
            }
        }
      
    `)
    return(
      <ProductItem data={data}/> 
    )
}

export default ProductList