import React from "react"
import Layout from "../components/layout"
import ProductHead from "../components/ProductHead/ProductHead"
import SEO from "../components/seo";
import ProductList from "../components/ProductsList/ProductList";

const OurProducts = () => {
  return (
    <Layout>
      <SEO
        title="iQubeLabs | Solutions Built"
        description="Explore the solutions we built in areas such as mobile and web application, data science and intelligent value added services"
      />
      <ProductHead />
      <ProductList />
    </Layout>
  )
}

export default OurProducts